<template>
  <div>
    <div class="backdrop" v-show="warningModal"></div>
    <Dialog header="Confirm force finish" :visible.sync="warningModal" >
      {{forceFinishText}}
      <template #footer>
        <CButton color="secondary" @click="warningModal=false">Cancel</CButton>
        <CButton color="danger" @click="cancelDelivery">Force finish</CButton>
    </template>
  </Dialog>
  

    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          sorter
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
        <!--         { key: "name", label: "Driver" },
        { key: "loadline", label: "Load line" },
        { key: "deliveryLocalDate", label: "Delivery date" },
        { key: "lastEvent", label: "Last event" },
        { key: "lastEventTimestamp", label: "Last event timestamp" }, -->
        <!-- <template #data.deliveryNO="{ item }">
            <td class="text-center">
              {{ item.data.deliveryNO ? item.data.deliveryNO : "" }}
            </td>
        </template>
        <template #deliveryLocalDate="{ item }">
            <td class="">
              {{ item.data.deliveryLocalDate ? moment(item.data.deliveryLocalDate).toLocaleString()  : "" }}
            </td>
        </template>
        <template #lastEvent="{ item }">
            <td class="">
              {{ item.events[item.events.length-1].eventName? item.events[item.events.length-1].eventName : "" }}
            </td>
          </template>
            <template #lastEventTimestamp="{ item }">
            <td class="">
              {{item.events[item.events.length-1].data.eventTimeStamp? moment(item.events[item.events.length-1].data.eventTimeStamp).toLocaleString() : ""}}
            </td>
        
        </template> -->

            <template #actions="{ item }">
            <td class="">
              <CButton
                color="danger"
                size="sm"
                class=""
                @click="cancelDeliveryDialog(item.deliveryNO)"
                ><CIcon name="cil-ban" style="margin-right: 5px;"/><span style="vertical-align: middle;">{{ $t("force_finish_loadline") }}</span></CButton>
            </td>
        
        </template> 
        <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import Dialog from 'primevue/dialog';
export default {
  name: "ListUnfinishedDeliveries",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
    Dialog
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    countryChossed: String,
  },
  computed: {
    _country() {
      return this.countryA;
    },
  },
  data() {
    return {
      layout: "list",
      tableTitle: this.$t('unfinished_load_lines'),
      tableFields: [
        // { key: "name", label: "Driver" },
        { key: "deliveryNO", label: this.$t('load_lines') },
        { key: "driverName", label: this.$t('driver_name') },
        { key: "truckPlate", label: this.$t('truck_plate') },
        { key: "deliveryLocalDate", label:  this.$t('delivery_local_date') },
        { key: "lastEvent", label: this.$t('last_event') },
        { key: "lastEventTimestamp", label: this.$t('last_event') + ' ' + this.$t('timestamp') },
        { key: "system", label: this.$t('system') },
        { key: "actions", label: this.$t('actions') },


      ],
      warningModal:false,
      forceFinishText:"",
      forceFinishDeliveryNO:null,
      listStatus: [],

      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  async created() {
    const self = this;

  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    cancelDeliveryDialog: function(deliveryNO){
      this.forceFinishDeliveryNO=deliveryNO;
      this.forceFinishText=`Are you sure you want to force finish load line ${this.forceFinishDeliveryNO}?`;
      this.warningModal=true;
    },
    cancelDelivery: async function(){
      var self = this;
      try{
        const res =  await self.$http.delete("apiConsole/console/forcefinishdelivery", {
          data: { deliveryNO: self.forceFinishDeliveryNO }
        });
        self.warningModal=false;
        self.$emit("refresh-data", {});
        self.showToastInfo("Load line finished successfully")
      }catch(err){
        self.warningModal=false;
        self.$emit("refresh-data", {});
        self.showToastError("An error has ocurred")
      }

    },    
    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;

      });
    },
  },  
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.buttons {
  display: flex;
}
.backdrop {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10100;
}
.p-dialog-mask{
  z-index:10101 !important;
}
</style>